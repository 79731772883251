import { QueryClient } from '@tanstack/react-query';

import { useLanguagesContentQuery } from '../data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../data/graphql/utils/get-content-in-current-language';

export const getLanguagesContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useLanguagesContentQuery.fetcher(),
    queryKey: useLanguagesContentQuery.getKey(),
  });

  const siteSettingsPageItems = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);
  const languagesList = data.LanguageIdAndName?.items;

  const cmsContent = {
    ...siteSettingsPageItems?.[0],
    languagesList: languagesList?.map((languageEntry) => ({
      languageId: languageEntry?.LanguageId,
      languageName: languageEntry?.LanguageName,
    })),
  };

  const languagesListFallback = [
    { languageId: 'en', languageName: 'English' },
    { languageId: 'id', languageName: 'Bahasa Indonesia' },
    { languageId: 'es', languageName: 'Español' },
    { languageId: 'vi', languageName: 'Tiếng Việt' },
    { languageId: 'th', languageName: 'ภาษาไทย' },
    { languageId: 'zh-CHT', languageName: '繁體中文' },
    { languageId: 'zh-CN', languageName: '中文(中国)' },
    { languageId: 'ko', languageName: '한국어' },
    { languageId: 'ja', languageName: '日本語' },
  ];

  return {
    languagesList: cmsContent.languagesList || languagesListFallback,
    selectLanguage: cmsContent.SelectLanguage || 'Select Language',
  };
};

export type LanguagesContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getLanguagesContent>>>
>;
