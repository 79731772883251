import { getLocale } from 'next-intl/server';

import { mapOptimizelyLocaleToIntlLocale } from '@shared/utils';

import { DEFAULT_LOCALE } from '../../../i18n/locales';

export const formatRelativePathLocale = async (relativePath: string | null | undefined) => {
  const cmsLocale = await getLocale();
  const locale = mapOptimizelyLocaleToIntlLocale(cmsLocale ?? 'en');

  return locale === DEFAULT_LOCALE ? relativePath : relativePath?.replace(/^\/[-\w]+\//u, '/');
};
